@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,400;0,600;0,800;1,600&display=swap');

body{
    margin: 0;
    padding: 0;
    font-family: 'Poppins' , sans-serif;
    background: var(--bg);
    transition: all 3ms ease;
    color: var(--black);
    scroll-behavior: smooth;
}
